
const ServiceData = [

        {
          id:"as1",
          icon: "fas fa-bell",
          iconColor: "#fd346e",
          title: "Team Accounts",
          text:
            "Stay on top of your task lists and stayin touch with what's happening",
        },
        {
          id:"as2",
          icon: "fas fa-chart-pie",
          iconColor:"#fcdc00",
          title: "API Reference",
          text:
            "Stay on top of your task lists and stayin touch with what's happening",
        },
        {
          id:"as3",
          icon: "fas fa-headphones",
          iconColor:"#ff5722",
          title: "Customer Support",
          text:
            "Stay on top of your task lists and stay in touch with what's happening",
        },
        {
          id:"as4",
          icon: "fas fa-envelope",
          iconColor:"#ff5722",
          title: "Email Marketing",
          text:
            "Stay on top of your task lists and stayin touch with what's happening",
        },
        {
          id:"as5",
          icon: "fas fa-layer-group",
          iconColor:"#6001d3",
          title: "Digital Agency",
          text:
            "Stay on top of your task lists and stay in touch with what's happening",
        },
  
      ]
  export default ServiceData;